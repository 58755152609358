/* crimson-text-latin-400-italic*/
@font-face {
  font-family: 'Crimson Text';
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: url('./files/crimson-text-latin-400-italic.woff2') format('woff2'), url('./files/crimson-text-all-400-italic.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* crimson-text-latin-400-normal*/
@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./files/crimson-text-latin-400-normal.woff2') format('woff2'), url('./files/crimson-text-all-400-normal.woff') format('woff');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
